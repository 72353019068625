import { Icon } from '@fluentui/react';
import React from 'react';

const EmailButton: React.FC = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:skills@d-fine.com';
  };

  return (
    <div onClick={handleEmailClick} style={{ cursor: 'pointer', fontSize: '24px', display: 'flex', alignItems: 'center' }}>
      <span role="img" aria-label="email">
      <Icon iconName="MailSolid" style={{ fontSize: '24px' }} />
      </span>
    </div>
  );
};

export default EmailButton;
