import {
  defaultProjectStartDate,
  defaultProjectEndDate,
  defaultProjectAbsenceThreshold,
  defaultProjectPostalCode,
  defaultProjectLocation,
  defaultProjectMaxDistance,
} from './options_defaults';
import { defaultKwePrompt, defaultRagPrompt } from '../prompts/prompts';
import { SearchSettings } from '../SearchComponent';

//------------------------------------------------------------------------------------------------------------
// USE CASES
//------------------------------------------------------------------------------------------------------------

// USE CASE 1: Hybrid Search with KWE and RAG
//------------------------------------------------------------------------------------------------------------

const avDateMin = new Date(2024, 5, 9);
const avDateMax = new Date(2025, 0, 1);
const entryDateAfter = new Date(1999, 0, 1);
const lastModifiedDateOlder = new Date();
const timeZoneOffsetHours = (-1 * avDateMin.getTimezoneOffset()) / 60;

avDateMin.setHours(avDateMin.getHours() + timeZoneOffsetHours);
avDateMax.setHours(avDateMax.getHours() + timeZoneOffsetHours);
entryDateAfter.setHours(entryDateAfter.getHours() + timeZoneOffsetHours);
lastModifiedDateOlder.setHours(
  lastModifiedDateOlder.getHours() + timeZoneOffsetHours
);

export const useCase1: SearchSettings = {
  dataSource: [
    { key: '0', text: 'Basic CV data' },
    { key: '1', text: 'Tender CVs' },
    { key: '2', text: 'Diamonds' },
  ],
  careerLevel: [],
  careerTrack: [],
  searchTypeOption: 1,
  toggleRag: true,
  searchModeOption: 0,
  queryTypeOption: 0,
  maxResults: 100,
  avDateMin: avDateMin,
  avDateMax: avDateMax,
  staffingStatus: [],
  staffingPriority: {
    grün: false,
    gelb: false,
    rot: false,
  },
  orgUnit: [
    { key: 'cuAAI', text: 'CU Applied AI' },
    { key: 'cuBEGIN', text: 'CU Buildings, Energy & Grid Infrastructure' },
    {
      key: 'cuBEGINH',
      text: 'CU Buildings, Energy & Grid Infrastructure Head-Unit',
    },
    { key: 'cuBCB', text: 'CU Business Control Banking' },
    { key: 'cuBCC', text: 'CU Business Control Corporates' },
    { key: 'cuBCI', text: 'CU Business Control Insurance & Asset Manager' },
    { key: 'cuCR', text: 'CU Credit Risk' },
    { key: 'cuDCCF', text: 'CU Digital Compliance & Corporate Finance' },
    { key: 'cuMNR', text: 'CU Market & Non-Financial Risk' },
    { key: 'cuMNO', text: 'CU Markets & Operations' },
    { key: 'cuSEB', text: 'CU Software Engineering & BDA' },
    { key: 'cuTS', text: 'CU Trading Systems' },
    { key: 'cuVAL', text: 'CU Valuation' },
    { key: 'cuGRIT', text: 'YU grIT' },
    { key: 'inCHEM', text: 'INTINC Chemistry' },
    { key: 'inDIS', text: 'INTINC d-fine Industrial Solutions' },
    { key: 'inMO', text: 'INTINC Mathematical Optimization' },
    { key: 'inMT', text: 'INTINC Mobility & Transportation' },
    { key: 'inPR', text: 'INTINC Pricing & Revenue Management' },
    { key: 'inRC', text: 'INTINC Retail & Consumer Products' },
    { key: 'management', text: 'Management' },
    { key: 'mainline', text: 'Mainline' },
    { key: 'nmoABC', text: 'NMO Applied Blockchain' },
    { key: 'nmoITGS', text: 'NMO IT Governance & Security' },
    { key: 'nmoPH', text: 'NMO Pharma & Healthcare' },
    { key: 'nmoPS', text: 'NMO Public Sector' },
  ],
  projectPostalCode: defaultProjectPostalCode,
  projectLocation: defaultProjectLocation,
  projectMaxDistance: defaultProjectMaxDistance,
  projectStartDate: defaultProjectStartDate,
  projectEndDate: defaultProjectEndDate,
  projectAbsenceThreshold: defaultProjectAbsenceThreshold,
  entryDateAfter: entryDateAfter,
  lastModifiedDateOlder: lastModifiedDateOlder,
  skillItems: [
    { typeValue: 'industry', textValue: 'Bank^3' },
    { typeValue: 'industry', textValue: 'Insurance' },
    { typeValue: 'expertise', textValue: 'Software*' },
    { typeValue: 'expertise', textValue: 'Programmieren' },
    { typeValue: 'skill', categoryValue: 'techSkills', textValue: 'Python', sliderValue: 2 },
    { typeValue: 'skill', categoryValue: 'techSkills', textValue: 'Jira*', sliderValue: 1 },
    { typeValue: 'skill', categoryValue: 'techSkills', textValue: 'SQL', sliderValue: 2 },
  ],
  searchQuery: `Position: Finanztechnologie-Entwickler (m/w/d)

Wir suchen einen erfahrenen Finanztechnologie-Entwickler (m/w/d), der/die in unserem Unternehmen eine entscheidende Rolle bei der Entwicklung und Implementierung von Softwarelösungen im Finanzwesen einnehmen wird. Als Teil unseres hochqualifizierten Teams werden Sie maßgeblich dazu beitragen, innovative technologische Lösungen zu entwickeln, die unseren Kunden im Finanzsektor einen Wettbewerbsvorteil verschaffen.

Anforderungen:

Abgeschlossenes Studium der Informatik, Wirtschaftsinformatik, Mathematik oder vergleichbare Qualifikation
Fundierte Kenntnisse in der Programmierung, insbesondere in den Sprachen Python, Java oder C++
Erfahrung in der Entwicklung von Finanzsoftware, idealerweise im Bereich Algorithmic Trading, Risikomanagement oder Finanzanalyse
Vertrautheit mit Finanzmarktregulierung und Compliance-Anforderungen (z.B. MiFID II, EMIR, Dodd-Frank)
Starkes analytisches Denkvermögen und die Fähigkeit, komplexe Probleme zu lösen
Teamfähigkeit, Kommunikationsstärke und eine proaktive Arbeitsweise

Aufgaben:

Entwicklung und Implementierung von maßgeschneiderten Softwarelösungen für Kunden im Finanzsektor
Enge Zusammenarbeit mit anderen Entwicklern, Analysten und Finanzexperten, um innovative Lösungen zu konzipieren und umzusetzen
Gewährleistung der Einhaltung von regulatorischen Anforderungen und Best Practices im Finanzbereich
Kontinuierliche Weiterentwicklung bestehender Systeme und Anwendungen, um den sich wandelnden Anforderungen des Marktes gerecht zu werden
Wir bieten eine spannende und herausfordernde Tätigkeit in einem dynamischen Umfeld sowie attraktive Entwicklungsmöglichkeiten. Wenn Sie Teil eines innovativen Teams werden
möchten und über die erforderlichen Fähigkeiten verfügen, senden Sie bitte Ihre Bewerbungsunterlagen an die unten angegebene Adresse.`,
  kwePrompt: defaultKwePrompt,
  ragPrompt: defaultRagPrompt,
};

// USE CASE 2: Full Text Search using the Query Helper
//------------------------------------------------------------------------------------------------------------

const avDateMin2 = new Date(2024, 5, 9);
const avDateMax2 = new Date(2025, 5, 22);
const entryDateAfter2 = new Date(1999, 0, 1);
const lastModifiedDateOlder2 = new Date();
const timeZoneOffsetHours2 = (-1 * avDateMin2.getTimezoneOffset()) / 60;

avDateMin2.setHours(avDateMin2.getHours() + timeZoneOffsetHours2);
avDateMax2.setHours(avDateMax2.getHours() + timeZoneOffsetHours2);
entryDateAfter2.setHours(entryDateAfter2.getHours() + timeZoneOffsetHours2);
lastModifiedDateOlder2.setHours(
  lastModifiedDateOlder2.getHours() + timeZoneOffsetHours2
);

export const useCase2: SearchSettings = {
  dataSource: [
    { key: '0', text: 'Basic CV data' },
    { key: '1', text: 'Tender CVs' },
    { key: '2', text: 'Diamonds' },
  ],
  careerLevel: [],
  careerTrack: [],
  searchTypeOption: 0,
  toggleRag: false,
  searchModeOption: 0,
  queryTypeOption: 0,
  maxResults: 100,
  avDateMin: avDateMin2,
  avDateMax: avDateMax2,
  staffingStatus: [],
  staffingPriority: {
    grün: false,
    gelb: false,
    rot: false,
  },
  orgUnit: [
    { key: 'cuAAI', text: 'CU Applied AI' },
    { key: 'cuBCB', text: 'CU Business Control Banking' },
    { key: 'cuCR', text: 'CU Credit Risk' },
    { key: 'mainline', text: 'Mainline' },
    { key: 'cuSEB', text: 'CU Software Engineering & BDA' },
  ],
  projectPostalCode: defaultProjectPostalCode,
  projectLocation: defaultProjectLocation,
  projectMaxDistance: defaultProjectMaxDistance,
  projectStartDate: defaultProjectStartDate,
  projectEndDate: defaultProjectEndDate,
  projectAbsenceThreshold: defaultProjectAbsenceThreshold,
  entryDateAfter: entryDateAfter2,
  lastModifiedDateOlder: lastModifiedDateOlder2,
  skillItems: [
    { typeValue: 'industry', textValue: 'Bank' },
    { typeValue: 'industry', textValue: 'Finanz*' },
    { typeValue: 'expertise', textValue: 'Software*' },
    { typeValue: 'expertise', textValue: 'Programmieren' },
    { typeValue: 'skill', categoryValue: 'techSkills', textValue: 'Python', sliderValue: 2 },
    { typeValue: 'skill', categoryValue: 'techSkills', textValue: 'TypeScript', sliderValue: 1 },
    { typeValue: 'skill', categoryValue: 'techSkills', textValue: 'Jira*', sliderValue: 1 },
  ],
  searchQuery: 'Web App, Frontend, Backend, Datenbank',
  kwePrompt: defaultKwePrompt,
  ragPrompt: defaultRagPrompt,
};

// USE CASE 3: Tender CV and New Project Mail Search
//------------------------------------------------------------------------------------------------------------

const avDateMin3 = new Date(2024, 0, 1);
const avDateMax3 = new Date(2025, 5, 7);
const entryDateAfter3 = new Date(1999, 0, 1);
const lastModifiedDateOlder3 = new Date();
const timeZoneOffsetHours3 = (-1 * avDateMin2.getTimezoneOffset()) / 60;

avDateMin3.setHours(avDateMin3.getHours() + timeZoneOffsetHours3);
avDateMax3.setHours(avDateMax3.getHours() + timeZoneOffsetHours3);
entryDateAfter3.setHours(entryDateAfter3.getHours() + timeZoneOffsetHours3);
lastModifiedDateOlder3.setHours(
  lastModifiedDateOlder3.getHours() + timeZoneOffsetHours3
);

export const useCase3: SearchSettings = {
  dataSource: [
    { key: '0', text: 'Basic CV data' },
    { key: '1', text: 'Tender CVs' },
    { key: '2', text: 'Diamonds' },
  ],
  careerLevel: [],
  careerTrack: [],
  searchTypeOption: 0,
  toggleRag: false,
  searchModeOption: 0,
  queryTypeOption: 1,
  maxResults: 100,
  avDateMin: avDateMin3,
  avDateMax: avDateMax3,
  staffingStatus: [],
  staffingPriority: {
    grün: false,
    gelb: false,
    rot: false,
  },
  orgUnit: [
    { key: 'cuAAI', text: 'CU Applied AI' },
    { key: 'cuBEGIN', text: 'CU Buildings, Energy & Grid Infrastructure' },
    {
      key: 'cuBEGINH',
      text: 'CU Buildings, Energy & Grid Infrastructure Head-Unit',
    },
    { key: 'cuBCB', text: 'CU Business Control Banking' },
    { key: 'cuBCC', text: 'CU Business Control Corporates' },
    { key: 'cuBCI', text: 'CU Business Control Insurance & Asset Manager' },
    { key: 'cuCR', text: 'CU Credit Risk' },
    { key: 'cuDCCF', text: 'CU Digital Compliance & Corporate Finance' },
    { key: 'cuMNR', text: 'CU Market & Non-Financial Risk' },
    { key: 'cuMNO', text: 'CU Markets & Operations' },
    { key: 'cuSEB', text: 'CU Software Engineering & BDA' },
    { key: 'cuTS', text: 'CU Trading Systems' },
    { key: 'cuVAL', text: 'CU Valuation' },
    { key: 'cuGRIT', text: 'YU grIT' },
    { key: 'inCHEM', text: 'INTINC Chemistry' },
    { key: 'inDIS', text: 'INTINC d-fine Industrial Solutions' },
    { key: 'inMO', text: 'INTINC Mathematical Optimization' },
    { key: 'inMT', text: 'INTINC Mobility & Transportation' },
    { key: 'inPR', text: 'INTINC Pricing & Revenue Management' },
    { key: 'inRC', text: 'INTINC Retail & Consumer Products' },
    { key: 'management', text: 'Management' },
    { key: 'mainline', text: 'Mainline' },
    { key: 'nmoABC', text: 'NMO Applied Blockchain' },
    { key: 'nmoITGS', text: 'NMO IT Governance & Security' },
    { key: 'nmoPH', text: 'NMO Pharma & Healthcare' },
    { key: 'nmoPS', text: 'NMO Public Sector' },
  ],
  projectPostalCode: defaultProjectPostalCode,
  projectLocation: defaultProjectLocation,
  projectMaxDistance: defaultProjectMaxDistance,
  projectStartDate: defaultProjectStartDate,
  projectEndDate: defaultProjectEndDate,
  projectAbsenceThreshold: defaultProjectAbsenceThreshold,
  entryDateAfter: entryDateAfter3,
  lastModifiedDateOlder: lastModifiedDateOlder3,
  skillItems: [],
  searchQuery: `quantittive~, "model prototypes"~10, "workshops vendors"~5, /[lp]ast/, bank*, software^5`,
  kwePrompt: defaultKwePrompt,
  ragPrompt: defaultRagPrompt,
};

//------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------
