import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Slider,
  TextField,
  DefaultButton,
  IconButton,
  Dropdown,
} from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import {
  buttonAddStyle,
  buttonRemoveStyle,
  stackDynamicItems,
  stackDynamicSingleItem,
  itemStyle,
  getSliderStyles,
  dropdownStylesSkills,
} from './react_styles_ui';

interface Item {
  typeValue: string;
  textValue: string;
  categoryValue?: string;
  searchTypeValue?: string;
  sliderValue?: number;
}

interface Props {
  maximumSliderLevel: number;
  sliderLevelMapping: string[];
  onSelectionChange: (
    typeValue: string,
    textValue: string,
    categoryValue: string,
    searchTypeValue: string,
    sliderValue: number,
    index: number
  ) => void;
  itemSelection: {
    typeValue: string;
    textValue: string;
    categoryValue?: string;
    searchTypeValue?: string;
    sliderValue?: number;
  }[];
  removeItem: (index: number) => void;
  queryTypeOption: number;
  searchTypeOption: number;
}

const DynamicTextfieldSlider: React.FC<Props> = ({
  onSelectionChange,
  maximumSliderLevel,
  sliderLevelMapping,
  itemSelection,
  removeItem,
  queryTypeOption,
  searchTypeOption,
}) => {
  // Initialize items
  const [items, setItems] = useState<Item[]>(itemSelection);

  // Handle add items
  const handleAddItems = () => {
    // Check if previous item has a non-empty text value before adding a new item
    const lastItem = items[items.length - 1];
    if (lastItem && lastItem.textValue.trim() === '') {
      toast.info(`Please fill in the text field before adding a new item!`);
      return;
    }
    setItems([
      ...items,
      {
        typeValue: 'skill',
        textValue: '',
        categoryValue: 'all', // Standardwert
        sliderValue: 0,
      },
    ]);
  };

  // Update selected items
  useEffect(() => {
    const updatedItems = itemSelection.map((item) => ({
      ...item,
      categoryValue:
        queryTypeOption === 1 || searchTypeOption === 1
          ? 'all'
          : item.categoryValue,
    }));
    setItems(updatedItems);
  }, [itemSelection, queryTypeOption, searchTypeOption]);

  const categoryOptions =
    queryTypeOption === 1 || searchTypeOption === 1 // Query type = "Semantic" cant perform fielded search in azure ai search, only query type = full (here: standard). So its not neccesary to select skill category
      ? [{ key: 'all', text: 'All' }]
      : [
          { key: 'all', text: 'All' },
          {
            key: 'techSkills',
            text: 'Technology, Software, Programming Language',
          },
          { key: 'certificate', text: 'Certificate' },
          { key: 'language', text: 'Language' },
          { key: 'methods', text: 'Methods & Processes' },
          { key: 'projectManagment', text: 'Project management' },
          { key: 'regulatoryStandards', text: 'Regulatory & Standards' },
          { key: 'additionalQualification', text: 'Additional Qualification' },
        ];

  // Construct DynamicTextfieldSlider component
  return (
    <div>
      {items.map((item, index) => (
        <Stack
          key={index}
          horizontal
          tokens={stackDynamicItems}
          styles={stackDynamicSingleItem}
        >
          <Dropdown
            placeholder="Select type"
            options={[
              { key: 'skill', text: 'Skill' },
              { key: 'industry', text: 'Industry' },
              { key: 'expertise', text: 'Expertise' },
            ]}
            selectedKey={item.typeValue} // Assuming 'type' is part of your item object
            onChange={(_, option) => {
              const newItems = [...items];
              newItems[index].typeValue = (option?.key as string) || '';

              setItems(newItems);
            }}
            styles={dropdownStylesSkills} // Adjust width as needed
          />
          {item.typeValue === 'skill' &&
            queryTypeOption !== 1 &&
            searchTypeOption !== 1 && (
              <Dropdown
                placeholder="Select category"
                options={categoryOptions}
                selectedKey={item.categoryValue}
                onChange={(_, option) => {
                  const newItems = [...items];
                  newItems[index].categoryValue = (option?.key as string) || '';
                  setItems(newItems);
                  onSelectionChange(
                    newItems[index].typeValue,
                    newItems[index].textValue,
                    newItems[index].categoryValue || '',
                    searchTypeOption.toString(),
                    newItems[index].sliderValue || 0,
                    index
                  );
                }}
                styles={dropdownStylesSkills}
              />
            )}
          <TextField
            value={item.textValue}
            onChange={(_, value) => {
              const newItems = [...items];
              newItems[index].textValue = value || '';
              onSelectionChange(
                item.typeValue,
                value || '',
                newItems[index].categoryValue || '',
                searchTypeOption.toString(),
                newItems[index].sliderValue || 0,
                index
              );
            }}
            placeholder="Enter text"
            styles={itemStyle}
          />
          {item.typeValue === 'skill' &&
            item.categoryValue !== 'certificate' && (
              <Slider
                min={0}
                max={maximumSliderLevel}
                value={item.sliderValue}
                valueFormat={(value) => sliderLevelMapping[value]}
                onChange={(value) => {
                  const newItems = [...items];
                  newItems[index].sliderValue = value;
                  setItems(newItems);
                  onSelectionChange(
                    item.typeValue,
                    item.textValue,
                    item.categoryValue || '',
                    searchTypeOption.toString(),
                    value,
                    index
                  );
                }}
                showValue
                disabled={!item.textValue.trim()}
                styles={getSliderStyles(item.sliderValue || 0)}
              />
            )}
          <IconButton
            onClick={() => removeItem(index)}
            styles={buttonRemoveStyle}
            iconProps={{ iconName: 'Delete' }}
            data-testid="delete-button"
          />
        </Stack>
      ))}
      <DefaultButton
        onClick={handleAddItems}
        styles={buttonAddStyle}
        iconProps={{ iconName: 'Add' }}
        wrap="true"
        data-testid="add-button"
      />
    </div>
  );
};

export default DynamicTextfieldSlider;
