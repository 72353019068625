import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  IconButton,
  Stack,
  IDropdownOption,
  PrimaryButton,
} from '@fluentui/react';
import { useCookies } from 'react-cookie';
import { AgGridReact } from 'ag-grid-react';
import { ITextFilterParams } from 'ag-grid-community';
import CVLink from './cv_link';
import ChatAssistantLink from './chat_assistant_link';
import MultiselectDropdown from './multiselect_dropdown';
import {
  dataStatisticsStyle,
  cvDataDiv,
  cvDataAgGridDiv,
  buttonModalCloseStyle,
  dataTableModalStyle,
  modalContentDiv,
} from './react_styles_ui';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Modal from 'react-modal';
import {
  getMaxTextWidth,
  updateOptions,
  percentageCellRenderer,
  daysCellRenderer,
  daysValueGetter,
  kmCellRenderer,
} from './functions';
import './data_table.css';
import axios from 'axios';
import { API_BASE_URL } from '../api/api_base_url';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { buttonStyleExport } from '../react_styles';

//Modal.setAppElement("#root"); // Currently not needed, app works fine without it. If integrated, it leads to errors when testing DataTable component. Mocking also did not work to solve the error.

interface RowData {
  [key: string]: any;
}

interface Props {
  data: RowData[];
}

const DataTable: React.FC<Props> = ({ data }) => {
  // Default column selection
  const columnsOption: IDropdownOption[] = useMemo(() => {
    return [
      { key: 'CorpId', text: 'ID' },
      { key: 'Availability', text: 'Availability' },
      { key: 'StaffingStatus', text: 'Status' },
      { key: 'CareerLevel', text: 'Level' },
      { key: 'CareerTrack', text: 'Track' },
      { key: 'OrgUnitI', text: 'Unit' },
      { key: 'Name', text: 'Name' },
      { key: 'Highlights_html', text: 'Highlights Summary' },
      { key: 'EntryDate', text: 'Entry Date' },
      { key: 'Diamonds_html', text: 'Diamonds' },
      { key: 'LastModified', text: 'Last Modified' },
      { key: 'Skills_html', text: 'Skills' },
      { key: 'Industry_html', text: 'Industry' },
      { key: 'Expertise_html', text: 'Expertise' },
      { key: 'RelevantWorkExperiences_DE_html', text: 'Work Experience (DE)' },
      { key: 'RelevantWorkExperiences_EN_html', text: 'Work Experience (EN)' },
      { key: 'TenderCVs_html', text: 'Tender CVs' },
      { key: 'FirstMonthAbsencesDays', text: 'First Month Absences (days)' },
      { key: 'FirstMonthAbsencesPercent', text: 'First Month Absences (%)' },
      { key: 'ProjectAbsencesDays', text: 'Project Absences (days)' },
      { key: 'ProjectAbsencesPercent', text: 'Project Absences (%)' },
      { key: 'Residence', text: 'Residence' },
      { key: 'Absences_html', text: 'Absences' },
      { key: 'ProjectLocationDistance', text: 'Project Location Distance' },
      { key: '@search.score', text: 'Search Score' },
      { key: '@search.reranker_score', text: 'Semantic Score' },
      { key: 'Highlights', text: 'Highlights' },
      { key: 'HighlightFields_html', text: 'Highlight Fields' },
    ];
  }, []);

  // Export Function using Ag-Grid
  const exportToExcel = () => {
    if (gridApiRef.current && columnApiRef.current) {
      const columnState = columnApiRef.current.getAllDisplayedColumns();
      const columnOrder = columnState.map((col: any) => col.getColId());

      const rowData: any[] = [];
      gridApiRef.current.forEachNode((node: any) => {
        if (node.data) {
          const cleaned = cleanRowData(node.data, columnOrder);
          rowData.push(cleaned);
        }
      });

      const worksheet = XLSX.utils.json_to_sheet(rowData, {
        header: columnOrder,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      saveAs(blob, 'Result.xlsx');
    } else {
      console.error('Ag-Grid API ist nicht bereit.');
    }
  };
  
  const cleanRowData = (row: any, columnOrder: string[]) => {
    const cleanedRow: any = {};
    const MAX_CELL_LENGTH = 32767; // Define the maximum length for a cell
  
    columnOrder.forEach((key) => {
      let value = row[key];
  
      // Check if value is null or undefined before processing
      if (value === null || value === undefined) {
        cleanedRow[key] = ''; // or handle it as you see fit, e.g., assign null or some default value
      } else if (typeof value === 'string') {
        // Remove HTML tags if value is a string
        value = value.replace(/<[^>]*>/g, '').trim();
        
        // Truncate value if it exceeds the maximum cell length
        if (value.length > MAX_CELL_LENGTH) {
          value = value.substring(0, MAX_CELL_LENGTH);
        }
  
        cleanedRow[key] = value;
      } else {
        cleanedRow[key] = value; // Assign non-string values directly
      }
    });
  
    return cleanedRow;
  };
  

  const selectedColumnsOption: IDropdownOption[] = useMemo(() => {
    return [
      { key: 'Availability', text: 'Availability' },
      { key: 'StaffingStatus', text: 'Status' },
      { key: 'CareerLevel', text: 'Level' },
      { key: 'CareerTrack', text: 'Track' },
      { key: 'OrgUnitI', text: 'Unit' },
      { key: 'Name', text: 'Name' },
      { key: 'Highlights_html', text: 'Highlights Summary' },
      { key: 'EntryDate', text: 'Entry Date' },
      { key: 'Diamonds_html', text: 'Diamonds' },
      { key: 'LastModified', text: 'Last Modified' },
      { key: 'Skills_html', text: 'Skills' },
      { key: 'RelevantWorkExperiences_DE_html', text: 'Work Experience (DE)' },
      { key: 'RelevantWorkExperiences_EN_html', text: 'Work Experience (EN)' },
      { key: 'TenderCVs_html', text: 'Tender CVs' },
      { key: '@search.score', text: 'Search Score' },
    ];
  }, []);

  // Initialize states
  const [dataVisible, setDataVisible] = useState(false);
  const [gridApi, setGridApi] = useState<any | null>(null);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  const [columnOptionsUpdate, setColumnOptionsUpdate] =
    useState<IDropdownOption[]>(columnsOption);
  const [selectedColumns, setSelectedColumns] =
    useState<IDropdownOption[]>(columnOptionsUpdate);
  const [cookies, setCookie] = useCookies(['selectedColumns', 'columnOptions']);
  const modalContentDivRef = useRef<HTMLDivElement | null>(null);

  // Ag-Grid API Referenz
  const gridApiRef = useRef<any>(null);
  const columnApiRef = useRef<any>(null);

  // Determine statistics
  const statistics = useMemo(() => {
    const numberOfResults = data.length;
    const careerLevelCounts = data.reduce((acc, item) => {
      acc[item.CareerLevel] = (acc[item.CareerLevel] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
    return { numberOfResults, careerLevelCounts };
  }, [data]);
  // Grid settings
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    gridApiRef.current = params.api;
    columnApiRef.current = params.columnApi;

    // Spaltenreihenfolge automatisch anpassen
    const columnState = params.columnApi.getColumnState();
    const allColumnIds = columnState.map((col: any) => col.colId);
    params.columnApi.autoSizeColumns(allColumnIds);
  };

  const onSelectionChanged = () => {
    const selectedRows = gridApi?.getSelectedRows();
    setSelectedItem(selectedRows?.length === 1 ? selectedRows[0] : null);
  };

  const openModal = (content: string) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleClick = (value: any) => {
    openModal(value);
  };

  const columnDefs = selectedColumns.map((option) => {
    const key = option.key.toString();
    const text = option.text.toString();
    if (
      key === 'Absences_html' ||
      key === 'Industry_html' ||
      key === 'TenderCVs_html' ||
      key === 'NewProjects_html' ||
      key === 'Diamonds_html' ||
      key === 'HighlightFields_html' ||
      key === 'RelevantWorkExperiences_DE_html' ||
      key === 'RelevantWorkExperiences_EN_html' ||
      key === 'Skills_html' ||
      key === 'Expertise_html'
    ) {
      // Define cellRenderer for specific columns
      return {
        headerName: text,
        field: key,
        cellRenderer: (params: any) => (
          <div
            onClick={() => handleClick(params.value)}
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        ),
        filter: 'agTextColumnFilter',
        hardcodedWidth:
          key === 'Diamonds_html'
            ? 500
            : key === 'Skills_html'
            ? 100
            : key === 'TenderCVs_html'
            ? 130
            : key === 'RelevantWorkExperiences_EN_html'
            ? 187
            : key === 'RelevantWorkExperiences_DE_html'
            ? 187
            : 100, // Default width for other keys
        maxSetWidth: 350,
        floatingFilter: true,
        filterParams: {
          browserDatePicker: false,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (key === 'Highlights_html') {
      // Define cellRenderer for specific columns
      return {
        headerName: text,
        field: key,
        cellRenderer: (params: any) => (
          <div
            onClick={() => handleClick(params.value)}
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        ),
        filter: 'agTextColumnFilter',
        maxSetWidth: 1000,
        floatingFilter: true,
        filterParams: {
          browserDatePicker: false,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (key === '@search.score' || key === '@search.reranker_score') {
      // Define valueGetter for scores
      return {
        headerName: text,
        field: key,
        cellRenderer: undefined,
        valueGetter: (params: any) => params.data[key],
        filter: 'agNumberColumnFilter',
        hardcodedWidth: 150,
        maxSetWidth: 350,
        floatingFilter: true,
        filterParams: {
          browserDatePicker: false,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (
      key === 'ProjectAbsencesDays' ||
      key === 'FirstMonthAbsencesDays'
    ) {
      // Define valueGetter and cellRenderer for absences
      return {
        headerName: text,
        field: key,
        cellRenderer: daysCellRenderer,
        valueGetter: daysValueGetter,
        filter: 'agNumberColumnFilter',
        maxSetWidth: 350,
        floatingFilter: true,
        filterParams: {
          browserDatePicker: false,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (
      key === 'ProjectAbsencesPercent' ||
      key === 'FirstMonthAbsencesPercent'
    ) {
      // Define cellRenderer for % columns
      return {
        headerName: text,
        field: key,
        cellRenderer: percentageCellRenderer,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        maxSetWidth: 350,
        filterParams: {
          browserDatePicker: false,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (key === 'ProjectLocationDistance') {
      // Define cellRenderer for km columns
      return {
        headerName: text,
        field: key,
        cellRenderer: kmCellRenderer,
        filter: 'agNumberColumnFilter',
        maxSetWidth: 350,
        floatingFilter: true,
        filterParams: {
          browserDatePicker: false,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else {
      // For other columns nothing special is needed
      return {
        headerName: text,
        field: key,
        filter: true,
        maxSetWidth:
          key === 'OrgUnitI'
            ? 120
            : key === 'CareerTrack'
            ? 90
            : key === 'CareerLevel'
            ? 100
            : key === 'StaffingStatus'
            ? 110
            : 350, // Default width for other keys
        floatingFilter: true,
        filterParams: {
          browserDatePicker: false,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    }
  });

  // Function to update column options and selections when moving columns
  const onColumnMoved = useCallback(
    (event: any) => {
      const columnState: any[] = event.api.getColumnState();
      const newColumnOrder: IDropdownOption[] = columnState.map(
        (column: any) => ({
          key: column.colId,
          text:
            columnsOption.find((option) => option.key === column.colId)?.text ||
            column.headerName,
        })
      );
      const updatedOptions = updateOptions(selectedColumns, columnsOption);
      setSelectedColumns(newColumnOrder);
      setColumnOptionsUpdate(updatedOptions);
      setCookie('selectedColumns', newColumnOrder, {
        secure: true,
        maxAge: 31536000,
      });
      setCookie('columnOptions', updatedOptions, {
        secure: true,
        maxAge: 31536000,
      });
    },
    [columnsOption, selectedColumns, setCookie]
  );

  // Function to update column options and selections based on dropdown selection order
  const handleColumnChange = (updatedSelectedItems: IDropdownOption[]) => {
    const updatedOptions = updateOptions(selectedColumns, columnsOption);
    setSelectedColumns(updatedSelectedItems);
    setColumnOptionsUpdate(updatedOptions);
    setCookie('selectedColumns', updatedSelectedItems, {
      secure: true,
      maxAge: 31536000,
    });
    setCookie('columnOptions', updatedOptions, {
      secure: true,
      maxAge: 31536000,
    });
  };

  // Function to open a new tab via double click
  const handleDoubleClick = (event: any) => {
    const selectedRowData = event.data;
    const link = `https://app.it-docker.d-fine.local/StaffingPortal/StaffApp/details/CV_View/${selectedRowData.CorpId}`;
    if (link) {
      window.open(link, '_blank');
    }
  };

  // Function to copy the file path in Tender CVs on button click
  const handleButtonClick = useCallback((event: Event) => {
    const target = event.currentTarget as HTMLButtonElement;
    const filePath = target.getAttribute('data-filepath');
    console.log('Button clicked, file path:', filePath);
    if (filePath) {
      navigator.clipboard.writeText(filePath).catch((err) => {
        console.error('Failed to copy path: ', err);
      });
    }
  }, []);

  // Function to copy the file path in Tender CVs on button click
  const handleOpenTenderClick = useCallback(async (event: Event) => {
    const target = event.currentTarget as HTMLButtonElement;
    const urlPdf = target.getAttribute('tender-sas-link') || 'default-value';
    console.log('Button clicked, file path:', urlPdf);
    if (urlPdf) {
      try {
        const apiUrl = `${API_BASE_URL}/api/download_pdf?url=${encodeURIComponent(
          urlPdf
        )}`;
        const response = await axios.get(apiUrl, { responseType: 'blob' });
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob); // Create a URL from the Blob
        const a = document.createElement('a'); // Create an anchor element
        a.href = url; // Set the anchor's href to the Blob URL
        a.download = 'tender_cv.pdf'; // Set the desired filename
        document.body.appendChild(a); // Append anchor to the body
        a.click(); // Trigger the download
        a.remove(); // Clean up the anchor element
        window.URL.revokeObjectURL(url); // Release the Blob URL
      } catch (error) {
        console.error('Failed to download file: ', error);
      }
    }
  }, []);

  // Calculate column width on first load
  useEffect(() => {
    if (!gridApi || data.length === 0 || !firstLoad) return;
    const columns = gridApi?.getColumnDefs();
    if (!columns) return;
    columns.forEach((column: any) => {
      if (column.hardcodedWidth) {
        gridApi?.setColumnWidths([
          { key: column.field, newWidth: column.hardcodedWidth },
        ]);
      } else {
        const font = '15px roboto';
        const maxWidth = getMaxTextWidth(data, column.field, font);
        const preferredWidth = Math.min(
          Math.ceil(maxWidth),
          column.maxSetWidth
        );
        gridApi?.setColumnWidths([
          { key: column.field, newWidth: preferredWidth },
        ]);
      }
    });
    setFirstLoad(true);
  }, [gridApi, columnDefs, data, firstLoad]);

  // Event listener to prevent scrolling of the main window when mouse is over the modal content div
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (
        modalIsOpen &&
        modalContentDivRef.current?.contains(e.target as Node)
      ) {
        const scrollTop = modalContentDivRef.current.scrollTop;
        const element = modalContentDivRef.current;
        const scrollHeight = element.scrollHeight;
        const height = element.clientHeight;
        const wheelDelta = e.deltaY;
        const isDeltaPositive = wheelDelta > 0;
        if (
          (isDeltaPositive && scrollTop + height >= scrollHeight) ||
          (!isDeltaPositive && scrollTop <= 0)
        ) {
          e.preventDefault();
        }
      }
    };
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [modalIsOpen]);

  // Event listener to copy file path for Tender CVs
  const addEventListeners = useCallback(() => {
    const modalDiv = modalContentDivRef.current;
    if (modalDiv) {
      const buttons = modalDiv.querySelectorAll('.styled-button');
      buttons.forEach((button) => {
        button.addEventListener('click', handleButtonClick);
      });
    }
  }, [handleButtonClick]);

  const removeEventListeners = useCallback(() => {
    const modalDiv = modalContentDivRef.current;
    if (modalDiv) {
      const buttons = modalDiv.querySelectorAll('.styled-button');
      buttons.forEach((button) => {
        button.removeEventListener('click', handleButtonClick);
      });
    }
  }, [handleButtonClick]);

  useEffect(() => {
    if (modalIsOpen) {
      const timeoutId = setTimeout(addEventListeners, 0);
      return () => {
        clearTimeout(timeoutId);
        removeEventListeners();
      };
    }
  }, [modalIsOpen, addEventListeners, removeEventListeners]);

  // Event listener to copy file path for Tender CVs
  const addEventListenersTender = useCallback(() => {
    const modalDiv = modalContentDivRef.current;
    if (modalDiv) {
      const buttons = modalDiv.querySelectorAll('.styled-button');
      buttons.forEach((button) => {
        button.addEventListener('click', handleOpenTenderClick);
      });
    }
  }, [handleOpenTenderClick]);

  const removeEventListenersTender = useCallback(() => {
    const modalDiv = modalContentDivRef.current;
    if (modalDiv) {
      const buttons = modalDiv.querySelectorAll('.styled-button');
      buttons.forEach((button) => {
        button.removeEventListener('click', handleOpenTenderClick);
      });
    }
  }, [handleOpenTenderClick]);

  useEffect(() => {
    if (modalIsOpen) {
      const timeoutId = setTimeout(addEventListenersTender, 0);
      return () => {
        clearTimeout(timeoutId);
        removeEventListenersTender();
      };
    }
  }, [modalIsOpen, addEventListenersTender, removeEventListenersTender]);

  // Load column selection and order from cookies on component mount
  useEffect(() => {
    const storedColumnSelection = cookies['selectedColumns'];
    const storedColumnOptions = cookies['columnOptions'];
    if (storedColumnSelection && storedColumnOptions) {
      setSelectedColumns(storedColumnSelection);
      setColumnOptionsUpdate(storedColumnOptions);
    } else {
      setSelectedColumns(columnsOption);
    }
  }, [columnsOption, cookies]);

  // Event listener for moving columns
  useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener('columnMoved', onColumnMoved);
    }
    return () => {
      if (gridApi) {
        gridApi.removeEventListener('columnMoved', onColumnMoved);
      }
    };
  }, [gridApi, onColumnMoved]);

  const getRowHeight = (params: any) => {
    const content = params.data?.Highlights_html || '';
    const lineCount = content.split('<br>').length;
    const baseHeight = 2;
    return baseHeight + (lineCount + 1) * 19;
  };

  const desiredOrder = [
    'Partner',
    'PE',
    'P',
    'SM',
    'M',
    'SC',
    'C',
    'MDS',
    'TLSE',
  ];

  // Construct DataTable component
  return (
    <div data-testid="DataTable">
      <div>
        <div className={dataStatisticsStyle}>
          <div>
            Total number of retrieved candidates: {statistics.numberOfResults} (
            {Object.entries(statistics.careerLevelCounts)
              .sort(
                ([a], [b]) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
              )
              .map(([level, count]) => `${level}: ${count}`)
              .join(', ')}
            )
          </div>
        </div>
      </div>
      <>
        {/* Export-Button mit Ag-Grid Export-Funktion */}
        <Stack horizontal horizontalAlign="space-between">
          <MultiselectDropdown
            placeholder="Select columns..."
            options={columnsOption}
            optionsStored={columnOptionsUpdate}
            selectedOptions={selectedColumnsOption}
            onChange={handleColumnChange}
            adjustOrder={true}
          />
          <PrimaryButton onClick={exportToExcel} styles={buttonStyleExport}>
            Export to Excel
          </PrimaryButton>
        </Stack>
        <div className="ag-theme-alpine">
          <div className={cvDataDiv}>
            <div className={cvDataAgGridDiv} data-testid="grid">
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={data}
                rowSelection="single"
                onSelectionChanged={onSelectionChanged}
                onRowDoubleClicked={(event: any) => handleDoubleClick(event)}
                onColumnMoved={onColumnMoved}
                onColumnPinned={onColumnMoved}
                suppressDragLeaveHidesColumns={true}
                getRowHeight={getRowHeight}
              />
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={dataTableModalStyle}
            ariaHideApp={false}
          >
            <IconButton
              onClick={closeModal}
              styles={buttonModalCloseStyle}
              iconProps={{ iconName: 'Clear' }}
              data-testid="close-modal"
            />
            <div
              className={modalContentDiv}
              dangerouslySetInnerHTML={{ __html: modalContent }}
              ref={modalContentDivRef}
              data-testid="modal-content"
            />
          </Modal>
        </div>
        {selectedItem && (
          <Stack horizontal>
            <CVLink CorpID={selectedItem.CorpId} />
            <ChatAssistantLink rowData={selectedItem} />
          </Stack>
        )}
      </>
    </div>
  );
};

export default DataTable;
