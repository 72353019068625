import React, { useState } from 'react';
import { PrimaryButton } from '@fluentui/react';
import {
  buttonStyleExpander,
  buttonStyleSearch,
  textStyle,
} from '../react_styles';

const Documentation = () => {
  const [expandedSections, setExpandedSections] = useState({
    documentation: true,
    fullTextSearch: false,
    hybridSearchOhneKWE: false,
    hybridSearchMitKWE: false,
    rag: false,
    queryTypes: false,
    searchModes: false,
  });

  const toggleSection = (section: keyof typeof expandedSections) => {
    if (section === 'documentation') {
      setExpandedSections((prev) => ({
        ...Object.keys(prev).reduce((acc, key) => {
          acc[key as keyof typeof expandedSections] = false;
          return acc;
        }, {} as typeof expandedSections),
        documentation: !prev.documentation,
      }));
    } else {
      setExpandedSections((prev) => ({
        ...prev,
        [section]: !prev[section],
      }));
    }
  };

  const handleBackNavigation = () => {
    window.location.href = '/#';
  };

  return (
    <div
      style={{
        paddingLeft: '40px',
        paddingRight: '40px',
        maxWidth: '1200px',
        margin: '0 auto',
        ...textStyle,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '15px 0 0 0',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 style={{ margin: 0 }}>
            Documentation: Overview of Search Options
          </h1>
          <PrimaryButton
            onClick={() => toggleSection('documentation')}
            styles={{
              ...buttonStyleExpander,
              root: {
                ...buttonStyleExpander?.root,
                marginLeft: '10px',
                width: '110px',
                height: '35px',
              },
            }}
          >
            {expandedSections.documentation ? 'Show Less' : 'Show More'}
          </PrimaryButton>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <PrimaryButton
            onClick={handleBackNavigation}
            styles={{
              ...buttonStyleSearch,
              root: {
                ...buttonStyleSearch?.root,
                width: '110px',
                height: '35px',
              },
            }}
          >
            Back
          </PrimaryButton>
        </div>
      </div>

      {expandedSections.documentation && (
        <>
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <h2 style={{ margin: 0 }}>Simple search</h2>
            <PrimaryButton
              onClick={() => toggleSection('fullTextSearch')}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                  marginLeft: '10px',
                  width: '110px',
                  height: '10px',
                },
              }}
            >
              {expandedSections.fullTextSearch ? 'Show Less' : 'Show More'}
            </PrimaryButton>
          </div>
          {expandedSections.fullTextSearch && (
            <section
              id="full-text-search"
              data-testid="section-full-text-search"
            >
              <p>
                <strong>Explanation:</strong> Searches for the exact terms
                specified in the search query.
              </p>
              <p>
                <strong>Example:</strong> For "financial institution OR credit
                risk", Simple search looks only for the exact terms "financial
                institution" or "credit risks" without considering related terms
                like "banking" or "financial risk".
              </p>
            </section>
          )}

          {/* <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <h2 style={{ margin: 0 }}>Hybrid Search ohne KWE</h2>
            <PrimaryButton
              onClick={() => toggleSection('hybridSearchOhneKWE')}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                  marginLeft: '10px',
                  width: '110px',
                  height: '10px',
                },
              }}
            >
              {expandedSections.hybridSearchOhneKWE ? 'Show Less' : 'Show More'}
            </PrimaryButton>
          </div>
          {expandedSections.hybridSearchOhneKWE && (
            <section
              id="hybrid-search-ohne-kwe"
              data-testid="section-hybrid-search-ohne-kwe"
            >
              <p>
                <strong>Function:</strong> Searches for exact terms and expands
                the search to include semantically related terms, allowing for
                the identification of contextually relevant results.
              </p>
              <p>
                <strong>Expansion:</strong> Combines Full Text Search and Vector
                Search to identify additional semantically relevant terms.
              </p>
              <p>
                <strong>Example:</strong> For "financial institution OR credit
                risk", Hybrid Search looks for exact matches as well as
                semantically related terms like "banking" or "financial risk".
              </p>
            </section>
          )} */}

          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <h2 style={{ margin: 0 }}>Smart search</h2>
            <PrimaryButton
              onClick={() => toggleSection('hybridSearchMitKWE')}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                  marginLeft: '10px',
                  width: '110px',
                  height: '10px',
                },
              }}
            >
              {expandedSections.hybridSearchMitKWE ? 'Show Less' : 'Show More'}
            </PrimaryButton>
          </div>
          {expandedSections.hybridSearchMitKWE && (
            <section
              id="hybrid-search-mit-kwe"
              data-testid="section-hybrid-search-mit-kwe"
            >
              <p>
                <strong>Function:</strong> Searches for exact terms and
                additionally uses extracted keywords to include semantically
                related content, thereby increasing the relevance of the
                results.
              </p>
              <p>
                <strong>Expansion:</strong> Smart search extends Simple search
                by integrating Keyword Extraction into the Vector Search. This
                extracts relevant keywords from the documents, enhancing the
                semantic relevance and precision of the search results by adding
                meaningful terms to the query. Additionally, you can use the
                Smart search prompt in conjunction with Smart Search to further
                specify the results. This feature is hidden in the advanced
                search options.
              </p>
              <p>
                <strong>Example:</strong> For "financial institution OR credit
                risk", Smart search identifies for exact terms and additionally
                extracts relevant keywords like "credit industry", "risk
                management", and "financial services". These keywords are used
                in the Vector Search to find contextually and semantically
                relevant content that goes beyond the direct terms.
              </p>
            </section>
          )}

          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <h2 style={{ margin: 0 }}>Chat results</h2>
            <PrimaryButton
              onClick={() => toggleSection('rag')}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                  marginLeft: '10px',
                  width: '110px',
                  height: '10px',
                },
              }}
            >
              {expandedSections.rag ? 'Show Less' : 'Show More'}
            </PrimaryButton>
          </div>
          {expandedSections.rag && (
            <section id="rag" data-testid="section-rag">
              <p>
                <strong>Explanation:</strong> Chat results option combines the
                search for relevant information with a generative model to
                generate precise and contextual answers based on the search
                results.
              </p>
              <p>
                <strong>Procedure:</strong>{' '}
              </p>
              <ol>
                <li>
                  <strong>Search:</strong> First, a search technology (e.g.
                  simple search, smart search) is used to find relevant
                  documents or content that matches the search query. This
                  search provides a list of entries based on the input terms.
                </li>
                <br />
                <li>
                  <strong>Generation:</strong> Chat results option is then
                  applied to the found entries. Chat results option uses this
                  information to generate a more precise answer. A generative
                  model (e.g. ChatGPT) formulates the response by using the
                  content of the found documents as context, which increases the
                  relevance and accuracy of the answer.
                </li>
              </ol>
            </section>
          )}

          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <h2 style={{ margin: 0 }}>Query Types</h2>
            <PrimaryButton
              onClick={() => toggleSection('queryTypes')}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                  marginLeft: '10px',
                  width: '110px',
                  height: '10px',
                },
              }}
            >
              {expandedSections.queryTypes ? 'Show Less' : 'Show More'}
            </PrimaryButton>
          </div>
          {expandedSections.queryTypes && (
            <section id="query-types" data-testid="section-query-types">
              <ul>
                {/* <li id="query-simple">
                  <strong style={{ fontSize: 'larger' }}>Simple</strong>
                  <ul>
                    <br />
                    <li>
                      <strong>Supported:</strong>
                      <ul style={{ paddingLeft: '20px' }}>
                        <li>
                          Basic logical operators: AND, OR, NOT (e.g.,{' '}
                          <strong>market AND risk</strong>,{' '}
                          <strong>bank OR insurance</strong>,{' '}
                          <strong>financial NOT banking</strong>).
                        </li>
                        <li>
                          Phrase search: "..." for exact phrases (e.g.,{' '}
                          <strong>"market risk"</strong>).
                        </li>
                      </ul>
                      <br />
                    </li>
                    <li>
                      <strong>Explanation:</strong> Suitable for simple, basic
                      search queries with straightforward operators.
                    </li>
                  </ul>
                </li>
                <br /> */}
                <li id="query-full">
                  <strong style={{ fontSize: 'larger' }}>Standard</strong>
                  <ul>
                    <br />
                    <li>
                      <strong>Supported:</strong>
                      <ul style={{ paddingLeft: '20px' }}>
                        <li>
                          Basic logical operators: AND, OR, NOT (e.g.,{' '}
                          <strong>market AND risk</strong>,{' '}
                          <strong>bank OR insurance</strong>,{' '}
                          <strong>financial NOT banking</strong>).
                        </li>
                        <li>
                          Phrase search: "..." for exact phrases (e.g.,{' '}
                          <strong>"market risk"</strong>).
                        </li>

                        <li>
                          Prefixing: + (mandatory terms) and - (exclusion)
                          (e.g., <strong>+insurance</strong>,{' '}
                          <strong>-risk</strong>).
                        </li>
                        <li>
                          Wildcards: * for zero or more characters, ? for one
                          character (e.g., <strong>invest*</strong> finds
                          "invest", "investment", "investing";{' '}
                          <strong>te?t</strong> finds "test", "text").
                        </li>
                        <li>
                          Fuzzy search: ~ for similar terms (e.g.,{' '}
                          <strong>market~</strong> finds "market", "markets",
                          "marketing").
                        </li>
                        <li>
                          Proximity search: "..."~n for terms within a specific
                          distance (e.g., <strong>"risk management"~5</strong>).
                        </li>
                        <li>
                          Range search: [start TO end] for value ranges (e.g.,{' '}
                          <strong>[2000 TO 2023]</strong>,{' '}
                          <strong>price:[100 TO 500]</strong>).
                        </li>
                        <li>
                          Regex search: /regex/ for patterns (e.g.,{' '}
                          <strong>/ba[k|n]k/</strong> finds "bank", "bakk").
                        </li>
                        <li>
                          Boosting: ^ to weight terms (e.g.,{' '}
                          <strong>banking^2</strong>, <strong>risk^0.5</strong>
                          ).
                        </li>
                      </ul>
                      <br />
                    </li>
                    <li>
                      <strong>Explanation:</strong> Allows detailed searches
                      with extensive control over search logic.
                    </li>
                  </ul>
                </li>
                <br />
                <li id="query-semantic">
                  <strong style={{ fontSize: 'larger' }}>Semantic</strong>

                  <ul>
                    <br />
                    <li>
                      <strong>Supported:</strong> Focuses on the meaning and
                      context of the search query, ignoring logical operators.
                    </li>
                    <br />
                    <li>
                      <strong>Explanation:</strong> Designed for capturing the
                      context and meaning of the search query for highly
                      relevant results.
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          )}

          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <h2 style={{ margin: 0 }}>Search Modes</h2>
            <PrimaryButton
              onClick={() => toggleSection('searchModes')}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                  marginLeft: '10px',
                  width: '110px',
                  height: '10px',
                },
              }}
            >
              {expandedSections.searchModes ? 'Show Less' : 'Show More'}
            </PrimaryButton>
          </div>
          {expandedSections.searchModes && (
            <section id="search-modes" data-testid="section-search-modes">
              <ul>
                <li id="search-any">
                  <strong>Search Mode "Any":</strong> Works similarly to an OR.
                  The search query finds documents that contain at least one of
                  the search terms, resulting in broader search results as the
                  search is less strict.
                </li>
                <br />
                <li id="search-all">
                  <strong>Search Mode "All":</strong> Works like an AND. The
                  search query finds only documents that contain all the
                  specified search terms, leading to more precise and narrower
                  search results as the criteria are stricter.
                  <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
                    <br />
                    <li>
                      <strong>Remark:</strong> Search Mode "All" combines terms
                      exactly according to the specified logical conditions and
                      displays only results that meet all the criteria exactly,
                      which is helpful for complex search queries.
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          )}
        </>
      )}
    </div>
  );
};

export default Documentation;
