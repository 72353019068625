import React, { useState, useEffect, useRef } from 'react';
import {
  mergeStyles,
  TextField,
  PrimaryButton,
  IconButton,
  Stack,
  Label,
  Spinner,
} from '@fluentui/react';
import { parseContentToHtml, calculateWidth } from './functions';
import { useAuth } from '../auth/auth_context';
import {
  labelStyle,
  labelStyleDetails,
  chatDiv,
  buttonStyleResetChat,
  textFieldStyleChat,
  stackChatField,
  chatTextFieldStackStyle,
  buttonStyleChat,
  buttonChatExpanderStyle,
  stackExpanderField,
  spinnerStyleChat,
  chatTextFieldItem,
  chatButtonItem,
  chatRoleLabelStyle,
  userMessageBaseStyle,
  assistantFirstMessageStyle,
  assistantMessageBaseStyle,
} from './react_styles_ui';

interface ChatMessage {
  content: string;
  role: string;
}

interface ChatProps {
  messages: ChatMessage[];
  onUserMessageSubmit: (message: string) => void;
  onResetChat: () => void;
  isSending: boolean;
}

const Chat: React.FC<ChatProps> = ({
  messages,
  onUserMessageSubmit,
  onResetChat,
  isSending,
}) => {
  // Initialize states and constants
  const { user } = useAuth();
  const [userInput, setUserInput] = useState('');
  const [chatVisible, setChatVisible] = useState(false);
  const firstMessage = messages.length > 0 ? messages[0] : null;

  // Function to handle user input change
  const handleUserInputChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserInput(event.currentTarget.value);
  };

  // Function to handle user message submission
  const handleUserMessageSubmit = () => {
    if (userInput.trim() !== '') {
      onUserMessageSubmit(userInput);
      setUserInput(''); // Clear the input field after submission
    } else {
      alert('Please enter a message.'); // Show an alert if input is empty
    }
  };

  // Scroll to the bottom of the window when messages are updated
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.role === 'assistant') {
      chatWindowRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [messages]);
  const chatWindowRef = useRef<HTMLDivElement>(null);

  // Function to handle sending message on Enter key press
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Check if the Enter key is pressed while Shift key is also pressed
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setUserInput(userInput + '\n');
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleUserMessageSubmit();
    }
  };

  // Function to reset the chat
  const handleResetChat = () => {
    onResetChat();
    setChatVisible(true);
  };

  // Construct Chat component
  return (
    <div data-testid="Chat">
      <Label styles={labelStyle}>Chat results:</Label>
      {firstMessage && (
        <div>
          <div className={assistantFirstMessageStyle}>
            <div
              dangerouslySetInnerHTML={{
                __html: parseContentToHtml(
                  firstMessage.content,
                  firstMessage.role
                ),
              }}
            />
          </div>
        </div>
      )}
      <Stack horizontal styles={stackExpanderField}>
        <IconButton
          iconProps={{ iconName: chatVisible ? 'ChevronUp' : 'ChevronDown' }}
          title={chatVisible ? 'Hide Chat' : 'Show Chat'}
          ariaLabel={chatVisible ? 'Hide Chat' : 'Show Chat'}
          onClick={() => setChatVisible((prevVisible) => !prevVisible)}
          styles={buttonChatExpanderStyle}
          data-testid="chat-button"
        />
        <Label styles={labelStyleDetails}>
          {chatVisible ? 'Hide chat' : 'Chat with your results'}
        </Label>
      </Stack>
      {chatVisible && messages.length > 1 && (
        <div className={mergeStyles()}>
          <Stack horizontal>
            <PrimaryButton
              onClick={handleResetChat}
              styles={buttonStyleResetChat}
            >
              {' '}
              Reset
            </PrimaryButton>
          </Stack>
        </div>
      )}
      {chatVisible && (
        <Stack horizontal>
          {messages.length > 1 && (
            <div className={mergeStyles(chatDiv)}>
              {messages.slice(1).map((message, index) => {
                const width = calculateWidth(message.content);
                const userMessageStyle = mergeStyles(userMessageBaseStyle, {
                  width: width,
                });
                const assistantMessageStyle = mergeStyles(
                  assistantMessageBaseStyle,
                  { width: width }
                );
                return (
                  <div
                    key={index}
                    className={
                      message.role === 'user'
                        ? userMessageStyle
                        : assistantMessageStyle
                    }
                  >
                    <div className={chatRoleLabelStyle}>
                      {message.role === 'assistant'
                        ? 'Skill Search Assistant'
                        : user?.userName}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parseContentToHtml(
                          message.content,
                          message.role
                        ),
                      }}
                    />
                  </div>
                );
              })}
              <div ref={chatWindowRef} />
            </div>
          )}
        </Stack>
      )}
      {chatVisible && (
        <Stack styles={chatTextFieldStackStyle}>
          <Stack horizontal styles={stackChatField}>
            <Stack.Item styles={chatTextFieldItem}>
              <TextField
                value={userInput}
                multiline
                rows={0}
                onChange={handleUserInputChange}
                onKeyDown={handleKeyPress}
                placeholder="Send a message..."
                styles={textFieldStyleChat}
              />
            </Stack.Item>
            <Stack.Item styles={chatButtonItem}>
              {isSending ? (
                <Spinner
                  ariaLive="assertive"
                  labelPosition="right"
                  styles={spinnerStyleChat}
                />
              ) : (
                <PrimaryButton
                  onClick={handleUserMessageSubmit}
                  styles={buttonStyleChat}
                  text="Send"
                  iconProps={{ iconName: 'Send' }}
                />
              )}
            </Stack.Item>
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default Chat;